exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artykuly-prawne-tsx": () => import("./../../../src/pages/artykuly-prawne.tsx" /* webpackChunkName: "component---src-pages-artykuly-prawne-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-porady-prawne-online-tsx": () => import("./../../../src/pages/porady-prawne-online.tsx" /* webpackChunkName: "component---src-pages-porady-prawne-online-tsx" */),
  "component---src-pages-uslugi-alimenty-tsx": () => import("./../../../src/pages/uslugi/alimenty.tsx" /* webpackChunkName: "component---src-pages-uslugi-alimenty-tsx" */),
  "component---src-pages-uslugi-aresztowania-zatrzymania-tsx": () => import("./../../../src/pages/uslugi/aresztowania-zatrzymania.tsx" /* webpackChunkName: "component---src-pages-uslugi-aresztowania-zatrzymania-tsx" */),
  "component---src-pages-uslugi-index-tsx": () => import("./../../../src/pages/uslugi/index.tsx" /* webpackChunkName: "component---src-pages-uslugi-index-tsx" */),
  "component---src-pages-uslugi-kontakty-z-dziecmi-tsx": () => import("./../../../src/pages/uslugi/kontakty-z-dziecmi.tsx" /* webpackChunkName: "component---src-pages-uslugi-kontakty-z-dziecmi-tsx" */),
  "component---src-pages-uslugi-przestepstwa-narkotykowe-tsx": () => import("./../../../src/pages/uslugi/przestepstwa-narkotykowe.tsx" /* webpackChunkName: "component---src-pages-uslugi-przestepstwa-narkotykowe-tsx" */),
  "component---src-pages-uslugi-rozwody-tsx": () => import("./../../../src/pages/uslugi/rozwody.tsx" /* webpackChunkName: "component---src-pages-uslugi-rozwody-tsx" */),
  "component---src-pages-uslugi-sprawy-karne-tsx": () => import("./../../../src/pages/uslugi/sprawy-karne.tsx" /* webpackChunkName: "component---src-pages-uslugi-sprawy-karne-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */)
}

